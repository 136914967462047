import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {Anchor, FireworkCTA} from 'src/components/controls';
import {Box, Image} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {aboutUs as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H3, P2} = Typography;

export const ExecutiveBox: typeof Box = styled(Box).attrs({
  width: ['100%', null, 'calc(50% - 0.75rem)', 'calc(25% - 1.5rem)'],
  mt: ['3rem', null, '1.5rem'],
  position: 'relative',
})`
  img {
    filter: grayscale(1);
    transition: filter 250ms;
  }

  .firework-cta {
    opacity: 0;
    transition: opacity 1s;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    &:hover {
      img {
        filter: grayscale(0);
      }

      .firework-cta {
        opacity: 1;
      }

      h3,
      p {
        color: ${themeGet('colors.periwinkle')};
      }
    }
  }
`;

const StyledH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, '2rem'],
  lineHeight: ['1.875rem', null, null, '2rem'],
  transition: 'color 240ms',
  mt: '1.5rem',
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  fontSize: ['', null, null, '1.0625rem'],
  lineHeight: ['', null, null, '1.3125rem'],
  fontWeight: fontWeights.bold,
  transition: 'color 240ms',
  mt: '0.75rem',
})``;

export const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'periwinkle',
  position: 'absolute',
  top: '50%',
  left: '50%',
})`
  transform: translate(-50%, -50%);
`;

type ExecutiveProps = {
  pictureUrl: string;
  name: string;
  title: string;
  bioUrl: string;
};

export const Executive: React.FC<ExecutiveProps> = ({...props}) => {
  return (
    <ExecutiveBox>
      <Anchor onClick={(e: MouseEvent) => FireworkCTAClick(e, 'about-us.executives', props.bioUrl)} to={props.bioUrl}>
        <Box position="relative">
          <Image src={props.pictureUrl} aria-label={`${props.name} headshot`} />

          <StyledFireworkCTA to={props.bioUrl}>{copy.executives.execCta}</StyledFireworkCTA>
        </Box>

        <StyledH3>{props.name}</StyledH3>

        <StyledP2>{props.title}</StyledP2>
      </Anchor>
    </ExecutiveBox>
  );
};
