import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Box, FireworkContainer} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {aboutUs as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H1, P2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({})``;

const StyledH1: typeof H1 = styled(H1).attrs({
  maxWidth: '51.125rem',
  fontSize: ['4.25rem', null, null, '5rem'],
  lineHeight: ['4.25rem', null, null, '5rem'],
})`
  span:nth-child(2) {
    color: ${themeGet('colors.neonGreen')};
    font-style: italic;
    display: block;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    span:nth-child(1) {
      display: block;
    }

    span:nth-child(2) {
      display: inline;
      margin-right: 0.75rem;
    }
  }
`;

const StyledP2: typeof P2 = styled(P2).attrs({
  maxWidth: '44rem',
  fontSize: '1.0625rem',
  lineHeight: '1.3125rem',
  mt: ['1.5rem'],
})``;

export const ImpactSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <Box maxWidth={props.containerMaxWidth} mx="auto" px="1.5rem">
        <StyledH1>
          {copy.maximize_impact.headingStrings.map((str, i) => (
            <span key={i}>{str}</span>
          ))}
        </StyledH1>

        <StyledP2>{copy.maximize_impact.description}</StyledP2>
      </Box>

      <Box width="100%" height="1px" mt={['3rem', null, null, '4.5rem']} backgroundColor="neonGreen" />
    </Wrapper>
  );
};
