import React from 'react';

import {Events} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {Box} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import styled from 'styled-components/macro';

const {P2} = Typography;

const NewsItemBox: typeof Box = styled(Box).attrs({
  width: ['100%', null, null, 'calc(33% - 1.5rem)'],
  mt: '3rem',
})``;

const StyledAnchor: typeof Anchor = styled(Anchor).attrs({
  color: 'periwinkle',
  fontSize: ['1.625rem', null, null, '1.75rem'],
  lineHeight: ['1.625rem', null, null, '1.75rem'],
  fontFamily: fonts.primary,
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  color: 'cream',
  position: 'relative',
  mt: '0.75rem',
})`
  span {
    font-weight: ${fontWeights.bold};

    &:before {
      content: ' | ';
      position: relative;
    }
  }
`;

type NewsItemProps = {
  title: string;
  url: string;
  year: string;
  source: string;
};

const handleClickLink = (clickText: string, clickUrl: string) => {
  Events.didClickLink({clickText, clickUrl});
};

export const NewsItem: React.FC<NewsItemProps> = ({...props}) => {
  return (
    <NewsItemBox>
      <StyledAnchor onClick={() => handleClickLink('about-us.news.item', props.url)} to={props.url} target="_blank">
        {props.title}
      </StyledAnchor>

      <StyledP2>
        {props.year}

        <StyledP2 as="span">{props.source}</StyledP2>
      </StyledP2>
    </NewsItemBox>
  );
};
