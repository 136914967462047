import React, {useMemo} from 'react';

import {BelieveSection} from 'src/components/about-us/BelieveSection';
import {ExecutiveTeamSection} from 'src/components/about-us/ExecutiveTeamSection';
import {ImpactSection} from 'src/components/about-us/ImpactSection';
import {NewsSection} from 'src/components/about-us/NewsSection';
import {Box, SEOWithQuery} from 'src/components/shared';
import * as copy from 'src/utils/copy';

const AboutUsPage: React.FC = ({...props}) => {
  const containerMaxWidth = '80.1875rem';

  return (
    <Box backgroundColor="darkGreen" color="cream">
      <ImpactSection containerMaxWidth={containerMaxWidth} />
      <BelieveSection containerMaxWidth={containerMaxWidth} />
      <ExecutiveTeamSection containerMaxWidth={containerMaxWidth} />
      <NewsSection containerMaxWidth={containerMaxWidth} />

      <SEOWithQuery title={copy.seo.aboutUsTitle} noIndex />
    </Box>
  );
};

export default AboutUsPage;
