import React from 'react';

import {Events} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {PRESS} from 'src/routes/paths';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {aboutUs as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

import {NewsItem} from './NewsItem';

const {H2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'midnightBlue',
  py: ['3rem', null, null, '4.5rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StyledAnchor: typeof Anchor = styled(Anchor).attrs({
  color: 'periwinkle',
  fontSize: '1rem',
  lineHeight: '1rem',
  fontFamily: fonts.heading,
  fontWeight: fontWeights.extraBold,
  textTransform: 'uppercase',
  letterSpacing: '0.1rem',
  textWrap: 'nowrap',
  mt: ['1.5rem', null, null, 0],
})`
  &:hover {
    text-decoration: underline;
  }
`;

const handleClickLink = (clickText: string, clickUrl: string) => {
  Events.didClickLink({clickText, clickUrl});
};

export const NewsSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <Box maxWidth={props.containerMaxWidth} mx="auto" px="1.5rem">
        <Flex
          flexDirection={['column', null, null, 'row']}
          justifyContent="space-between"
          alignItems={['flex-start', null, null, 'center']}
        >
          <StyledH2>{copy.news.heading}</StyledH2>

          <StyledAnchor onClick={() => handleClickLink('about-us.news.view-all', PRESS)} to={PRESS}>
            {copy.news.cta}
          </StyledAnchor>
        </Flex>

        <Flex
          flexDirection={['column', null, null, 'row']}
          justifyContent="space-between"
          flexWrap={[null, null, null, 'wrap']}
        >
          {copy.news.items.map((item, i) => (
            <NewsItem key={i} {...item} />
          ))}
        </Flex>
      </Box>
    </Wrapper>
  );
};
